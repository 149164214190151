@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-Regular.otf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-Italic.otf") format("truetype");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-SemiBold.otf") format("truetype");
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-SemiBoldItalic.otf")
		format("truetype");
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-Black.otf") format("truetype");
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: "RedHatDisplay";
	src: url("./assets/fontes/RedHatDisplay-BlackItalic.otf") format("truetype");
	font-style: italic;
	font-weight: 900;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "RedHatDisplay", sans-serif;
}

:root {
	/*border-radius*/
	--border-radius-s: 10px;
	--border-radius-m: 20px;
	--border-radius-l: 50px;
	--border-radius-circle: 100%;

	/*font-weight*/
	--strong-weight: 800;
	--regular-weight: 600;
	--weak-weight: 400;

	/*font-size*/
	/* Tip: biggest+medium, bigger+small */
	--biggest-font: 2.7em;
	--bigger-font: 1.8em;
	--big-font: 1.5em;
	--medium-font: 1.2em;
	--small-font: 1em;
	--smaller-font: 0.9em;
	--smallest-font: 0.8em;

	/* width */
	--max-section-w: 1200px;
}

/* Personalização do Bootstrap */

/* Navbar global */
.navbar-expand-lg.offcanvas.offcanvas-body {
	justify-content: flex-end;
}

/* Componente: TituloFeaturesTexto */
#navigationTabs li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #f26a35!important;
	border: 2px solid #f26a35!important;
}

#navigationTabs li button.active {
	background-color: #f26a35!important;
	color: white!important;
}

#navigationTabsWaymenu li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #f09400!important;
	border: 2px solid #f09400!important;
}

#navigationTabsWaymenu li button.active {
	background-color: #f09400!important;
	color: white!important;
}

#navigationTabsWaypay li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #2895C9!important;
	border: 2px solid #2895C9!important;
}

#navigationTabsWaypay li button.active {
	background-color: #2895C9!important;
	color: white!important;
}

#navigationTabsWayservice li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #ED6A37!important;
	border: 2px solid #ED6A37!important;
}

#navigationTabsWayservice li button.active {
	background-color: #ED6A37!important;
	color: white!important;
}

#navigationTabsEfex li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #ee0000!important;
	border: 2px solid #ee0000!important;
}

#navigationTabsEfex li button.active {
	background-color: #ee0000!important;
	color: white!important;
}

#navigationTabsLumien li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #4ea32a!important;
	border: 2px solid #4ea32a!important;
}

#navigationTabsLumien li button.active {
	background-color: #4ea32a!important;
	color: white!important;
}

#navigationTabsFlutt li button {
	padding: 0.5em;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
	border-radius: var(--border-radius-s);
	width: 100%;
	height: 100%;
	color: #000087!important;
	border: 2px solid #000087!important;
}

#navigationTabsFlutt li button.active {
	background-color: #000087!important;
	color: white!important;
}

#navigationTabs li, 
#navigationTabsWaymenu li,
#navigationTabsEfex li,
#navigationTabsLumien li,
#navigationTabsFlutt li,
#navigationTabsWaypay li,
#navigationTabsWayservice li {
	flex: 1;
}

#navigationTabs, 
#navigationTabsWaymenu, 
#navigationTabsEfex,
#navigationTabsLumien,
#navigationTabsFlutt,
#navigationTabsWaypay li,
#navigationTabsWayservice li {
	border: none;
}

.accordion-item {
	background-color: transparent!important;
	border: none!important;
}

.accordion-button::after {
	display: none!important;
}

/* Navbar dropdown */
.nav-link {
	text-decoration: none!important;
	color: #1b355e!important;
	font-weight: var(--regular-weight)!important;

	&:hover {
		color: #1b355e!important;
	}

	&:link {
		color: #1b355e!important;
	}

	&:visited {
		color: #1b355e!important;
	}
}

.dropdown-item {
    margin: 1em 0!important;
}